import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from 'helpers/api';

export const updateLanguage = createAsyncThunk('appConfig/updateLanguage', async (params) => {
  return params;
})

export const updateModalLanguage = createAsyncThunk('appConfig/updateModalLanguage', async (params) => {
  return params;
})

export const updateFaqsLanguage = createAsyncThunk('appConfig/updateFaqsLanguage', async (params) => {
  return params;
})

export const updateLoading = createAsyncThunk('appConfig/updateLoading', async (params) => {
  return params;
})

export const updateHeight = createAsyncThunk('appConfig/updateHeight', async (params) => {
  return params;
})

export const updateNotificationsModal = createAsyncThunk('appConfig/updateNotificationsModal', async (params) => {
  return params;
})

export const updateTheme = createAsyncThunk('appConfig/updateTheme', async (params) => {
  return params;
})

export const updateSocketStatus = createAsyncThunk('appConfig/updateSocketStatus', async (params) => {
  return params;
})

export const validToken = createAsyncThunk('appConfig/validToken', async (params) => {
  return params;
})

export const fetchFaqs = createAsyncThunk('appConfig/fetchFaqs', async () => {
  const response = await api.get('api/faqs/list')
  return response?.data
})

export const appConfigSlice = createSlice({
  name: 'appConfig',
  initialState: {
    theme: "darkTheme",
    language: "pt",
    languageModalOpen: false,
    faqsModalOpen: false,
    notificationsModalOpen: false,
    loading: false,
    validToken: null,
    activeSocket: false,
    screenAvailableHeight: false
  },

  reducers: {},
  extraReducers: builder => {
    builder.addCase(updateLanguage.fulfilled, (state, action) => {
      state.language = action.payload
    })
    builder.addCase(updateModalLanguage.fulfilled, (state, action) => {
      state.languageModalOpen = action.payload
    })
    builder.addCase(updateNotificationsModal.fulfilled, (state, action) => {
      state.notificationsModalOpen = action.payload
    })
    builder.addCase(updateFaqsLanguage.fulfilled, (state, action) => {
      state.faqsModalOpen = action.payload
    })
    builder.addCase(updateLoading.fulfilled, (state, action) => {
      state.loading = action.payload
    })
    builder.addCase(updateHeight.fulfilled, (state, action) => {
      state.screenAvailableHeight = action.payload
    })
    builder.addCase(updateTheme.fulfilled, (state, action) => {
      state.theme = action.payload
    })
    builder.addCase(validToken.fulfilled, (state, action) => {
      state.validToken = action.payload
    })
    builder.addCase(updateSocketStatus.fulfilled, (state, action) => {
      state.activeSocket = action.payload
    })
  }
})

export default appConfigSlice.reducer
