import React, { useState, useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import { translationFlags } from 'config/translationFlags';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as AlegroMobileLogo } from 'assets/images/logos/alegroMobileLogo.svg';
import { ReactComponent as NotificationsIcon } from 'assets/images/icons/notificationsBell.svg';
import { ReactComponent as Help } from 'assets/images/icons/IconHelp.svg';
import { updateFaqsLanguage, updateModalLanguage, updateLanguage, updateNotificationsModal, updateTheme } from 'store/apps/appConfig';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import CustomCard from 'components/common/cards/customCard';
import { fetchSettings } from 'store/apps/marketplace';
import { useRouterTransition } from 'hooks/useRouterTransition';
import { checkVisitingTime } from 'hooks/pricesAndSchedules';
import { pathnameCheckScheduleAndOffline, pathnameCheckTerms, pathnamePaymentMissing } from 'config/appConfig';


const Navbar = () => {

  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  let { handleTransition } = useRouterTransition(navigate);
  const location = useLocation();
  const [selectedCountry, setSelectedCountry] = useState(false);
  const { Data: notifications } = useSelector(state => state?.notifications);
  const { theme, faqsModalOpen, languageModalOpen, notificationsModalOpen } = useSelector(state => state?.appConfig);
  const { settings } = useSelector(state => state?.marketplace);
  const { termsAndConditions } = useSelector(state => state?.user);

  const navTo = (url, state) => {
    if (state) {
      handleTransition(url, state)
    }
    else {
      handleTransition(url)
    }
  };

  const openLanguageChange = () => {
    if (languageModalOpen) {
      dispatch(updateModalLanguage(false))
    }
    else {
      dispatch(updateModalLanguage(true))
    }
    dispatch(updateFaqsLanguage(false));
    dispatch(updateNotificationsModal(false))
  }

  const openFaqs = () => {
    if (faqsModalOpen) {
      dispatch(updateFaqsLanguage(false));
    }
    else {
      dispatch(updateFaqsLanguage(true));
    }
    dispatch(updateModalLanguage(false))
    dispatch(updateNotificationsModal(false))
  }

  const openNotifications = () => {
    if(notificationsModalOpen){
      dispatch(updateNotificationsModal(false))
    }
    else{
      dispatch(updateNotificationsModal(true))
    }
    dispatch(updateModalLanguage(false))
    dispatch(updateFaqsLanguage(false));
  }

  const goHome = () => {
    dispatch(updateFaqsLanguage(false));
    dispatch(updateModalLanguage(false));
    dispatch(updateNotificationsModal(false))
    navigate('/home');
  }

  const getNotificationsNotReaded = () => {
    const filteredNotifications = notifications.filter(item => item.readed === false);
    return (filteredNotifications.length);
  }

  const handleToggleChange = () => {
    dispatch(updateTheme(theme === "lightTheme" ? "darkTheme" : "lightTheme"))
  };

  const getSettings = () => {
    dispatch(fetchSettings({ type: "visitor" }))
  }

  useEffect(() => {
    setSelectedCountry(translationFlags.find(key => key?.code === i18n.language))
    dispatch(updateLanguage(i18n.language))
  }, [i18n.language])

  useEffect(() => {
    i18n.changeLanguage('pt')
  }, []);

  useEffect(() => {
    if (settings) {
      if (pathnameCheckScheduleAndOffline.includes(location?.pathname)) {
        if (location?.pathname !== "/home") {
          if (settings?.payments.some(payment => payment?.enabled)) {
            checkVisitingTime(settings?.openingSchedule, navTo);
          }
          else {
            if (settings?.freeMode?.free) {
              checkVisitingTime(settings?.openingSchedule, navTo);
            }
          }
        }
      }
    }
    else {
      dispatch(fetchSettings({ type: "visitor" }))
        .then((res) => {
          if (res?.payload?.ResultCode === 1) {
            if (pathnameCheckScheduleAndOffline.includes(location?.pathname)) {
              if (location?.pathname !== "/home") {
                if (res.payload?.Data?.payments.some(payment => payment?.enabled)) {
                  checkVisitingTime(res.payload?.Data?.openingSchedule, navTo);
                }
                else {
                  if (res.payload?.Data?.freeMode?.free) {
                    checkVisitingTime(res.payload?.Data?.openingSchedule, navTo);
                  }
                }
              }
            }
          }
        })
    }
  }, [location, settings])

  useEffect(() => {
    dispatch(updateFaqsLanguage(false))
    dispatch(updateModalLanguage(false))
    dispatch(updateNotificationsModal(false))
  }, [location?.pathname])

  useEffect(() => {
    getSettings()
  }, [dispatch])

  useEffect(() => {
    if (settings) {
      if (!settings?.payments.some(payment => payment?.enabled) && pathnamePaymentMissing.includes(location?.pathname) && !settings?.freeMode?.free) {
        navTo('/service-down');
      }
    }
  }, [settings, pathnamePaymentMissing, location]);

  useEffect(() => {
    if (pathnameCheckTerms.includes(location?.pathname) && !termsAndConditions) {
      navTo('/terms-conditions');
    }
  }, [termsAndConditions, location])

  return (
    <Row style={{ height: "45px" }}>
      <Col xs={location?.pathname === "/home" ? "7" : "3"} className='h-100' style={{ zIndex: 1 }}>
        {
          location?.pathname === "/home" ?
            <Row className="h-100 w-fit-content">
              <Col xs="12" onClick={() => openFaqs()}>
                <CustomCard color="secondary">
                  <span className='m-0 p-0 px-2 text-4 weight-300'>{t('app.helpAndFaqs')}</span>
                </CustomCard>
              </Col>
            </Row>
            :
            <Row>
              <AlegroMobileLogo className='p-0 m-0' onClick={() => goHome()} style={{ width: "auto", height: "45px" }} />
            </Row>
        }
      </Col>
      <Col xs={location?.pathname === "/home" ? "5" : "9"} className='h-100 d-flex justify-content-end ' style={{ zIndex: 1 }}>
        <Row>
          <Col className='me-2'>
            <Row style={{ width: "45px" }} className="h-100">
              <Col xs="12">
                <CustomCard color="secondary">
                  <div className='m-0 p-0 h-100 w-auto d-flex align-items-center'>
                    <input id="toggle" className="toggle m-0 p-0" type="checkbox" checked={theme === "lightTheme"} onChange={handleToggleChange} />
                  </div>
                </CustomCard>
              </Col>
            </Row>
          </Col>
          {location?.pathname !== "/home" &&
            <Col className='me-2'>
              <Row style={{ width: "45px" }} className="h-100" onClick={() => openFaqs()}>
                <Col xs="12">
                  <CustomCard color="secondary">
                    <div className='m-0 p-0 h-100 w-100 d-flex justify-content-center align-items-center'>
                      <Help id="modals" style={{ height: "37.5%", fill: "var(--font)" }} />
                    </div>
                  </CustomCard>
                </Col>
              </Row>
            </Col>}
          {location?.pathname === "/access-lockers" &&
            <Col className='me-2'>
              <Row style={{ width: "45px" }} className="h-100" onClick={() => openNotifications()}>
                <Col xs="12">
                  <CustomCard color="secondary">
                    <div className='m-0 p-0 h-100 w-100 d-flex justify-content-center align-items-center position-relative'>
                      {(notifications?.length > 0 && getNotificationsNotReaded() > 0) && <div className='m-0 p-0 position-absolute d-flex justify-content-center align-items-center' style={{ background: "#FFBC64", borderRadius: "50%", width: 15, height: 15, top: 7, right: 9 }}>
                        {getNotificationsNotReaded() < 100 && <span className='p-0' style={{ fontSize: 9.5, marginTop: 1 }}>{getNotificationsNotReaded()}</span>}
                      </div>}
                      <NotificationsIcon className='m-0 p-0 w-auto' style={{ height: "42.5%", stroke: "var(--font)", fill: "var(--font)" }} />
                    </div>
                  </CustomCard>
                </Col>
              </Row>
            </Col>}
          <Col id="modals">
            <Row style={{ width: "45px" }} className="h-100" id="modals">
              <Col xs="12" id="modals">
                <CustomCard color="secondary" id="modals">
                  <Col xs="12" id="modals" className='h-100 position-relative d-flex align-items-center justify-content-center' onClick={() => openLanguageChange()}>
                    <div className='border-radius w-100 h-100 position-absolute' id="modals" />
                    <Row className='px-1 flag' id="modals">
                      <div id="modals" className='m-0 p-0 h-100' style={{ width: "32.5px" }}>
                        {selectedCountry?.flag}
                      </div>
                    </Row>
                  </Col>
                </CustomCard>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default Navbar;
