import React, { useEffect } from "react"
import Router from "router";
import { connect } from "react-redux";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App({ theme }) {

  useEffect(() => {
    if (theme === 'lightTheme') {
      document.documentElement.setAttribute('data-theme', 'lightTheme')
    }
    else {
      document.documentElement.setAttribute('data-theme', 'darkTheme')
    }
  }, [theme])

  return (
    <>
      <Router />
      <ToastContainer limit={3} />
    </>
  );
}


const mapStateToProps = (state) => {
  return {
    theme: state?.appConfig?.theme,
  };
};

export default connect(mapStateToProps)(App);


