import React, { useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';
import Navbar from 'components/navbar';
import { useDispatch, useSelector } from 'react-redux';
import Loading from 'components/common/loading/Loading';
import Portal from 'helpers/Portal';
import FaqsModal from 'components/common/modals/faqsModal';
import LanguageModal from 'components/common/modals/languageModal';
import { updateHeight } from 'store/apps/appConfig';
import { ReactComponent as Power } from 'assets/images/logos/PoweredByLokk.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRouterTransition } from 'hooks/useRouterTransition';
import NotificationsModal from 'components/common/modals/notificationsModal';
import Socket from 'helpers/socket';

const AppLayout = ({ children }) => {

  const [safeAreaHeight, setSafeAreaHeight] = useState(window.innerHeight);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  let { handleTransition } = useRouterTransition(navigate);
  const { screenAvailableHeight: screenHeight, faqsModalOpen, languageModalOpen, notificationsModalOpen, loading, theme, validToken } = useSelector(state => state.appConfig);

  const navTo = (url, state) => {
    if (state) {
      handleTransition(url, state)
    }
    else {
      handleTransition(url)
    }
  };

  const getModalOpen = () => {
    if (languageModalOpen) {
      return <LanguageModal />;
    }
    else if (faqsModalOpen) {
      return <FaqsModal />;
    }
    else if (notificationsModalOpen) {
      return <NotificationsModal />
    }
    else {
      return null;
    }
  }

  useEffect(() => {
    function handleResize() {
      const newSafeAreaTop = window.top !== window ? 0 : window.screenTop;
      const newSafeAreaHeight = window.innerHeight - newSafeAreaTop;
      setSafeAreaHeight(newSafeAreaHeight);
      dispatch(updateHeight(newSafeAreaHeight));
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (!screenHeight) {
      dispatch(updateHeight(window.innerHeight - (window.top !== window ? 0 : window.screenTop)));
    }
  }, [screenHeight])

  useEffect(() => {
    if (validToken === false) {
      navTo('/invalid-token')
    }
  }, [validToken, location])


  return (
    <Row className='m-0 p-0 position-relative overflow-hidden' style={{ background: "var(--background)", minHeight: `${safeAreaHeight}px` }} >
      <Socket token={process.env.REACT_APP_API_TOKEN} showIconStatus />
      {
        loading &&
        <Loading />
      }
      <Power style={{ width: "90px", zIndex: 1, height: "20px", bottom: 10, left: "calc(50% - 45px)", fill: `var(${theme === "lightTheme" ? "--font" : "--white"})` }} className="m-0 m p-0 position-absolute" />
      <Col xs="12">
        <Row className='p-4 h-100'>
          <Col xs="12" id="navbar" style={{ height: "45px" }}>
            <Navbar />
          </Col>
          <Col xs="12" id="content-container" style={{ height: "calc(100% - 85px)" }} className="overflow-hidden">
            <Portal isOpen={languageModalOpen || faqsModalOpen || notificationsModalOpen}>
              {
                getModalOpen()
              }
            </Portal>
            {children}
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default AppLayout;
