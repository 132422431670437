import React from 'react';
import { Row, Col } from 'reactstrap';
import { updateModalLanguage } from 'store/apps/appConfig';
import { useDispatch, connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { translationFlags } from 'config/translationFlags';
import CustomCard from 'components/common/cards/customCard';
import NavigationTitle from '../navigationTitle';
import { ReactComponent as Power } from 'assets/images/logos/PoweredByLokk.svg';


const LanguageModal = ({ language,theme }) => {

  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const changeCountry = (country) => {
    i18n.changeLanguage(country?.code);
    dispatch(updateModalLanguage(false))
  }

  return (
    <Row className='m-0 h-100 w-100'>
      <Power style={{ width: "90px", height: "20px", bottom: 10, left: "calc(50% - 45px)", fill: `var(${theme === "lightTheme" ? "--font" : "--white"})` }} className="m-0 m p-0 position-absolute" />
      <Col xs="12" style={{ height:98 }}>
        <NavigationTitle title={t('app.language')} modal modalLanguage />
      </Col>
      <Col xs="12" style={{ height: "calc(100% - 98px)" }} >
        <Row className='mt-4'>
          {translationFlags.map((item, index) =>
            <Col key={index} xs="12" className='my-2' onClick={() => changeCountry(item)}>
              <CustomCard color={language === item?.code ? "lightRose" : "secondary"}>
                <div className="m-0 p-0 p-3 w-100 h-fit-content d-flex flex-row">
                  <div className='m-0 p-0' style={{ width: "32.5px" }}>{item?.flag}</div>
                  <div className='m-0 p-0 d-flex align-items-center ps-3 text-4 weight-300'>{item?.language}</div>
                </div>
              </CustomCard>
            </Col>
          )}
        </Row>
      </Col>
    </Row>
  );
}

const mapStateToProps = (state) => {
  return {
    language: state?.appConfig?.language,
    theme: state?.appConfig?.theme
  };
};

export default connect(mapStateToProps)(LanguageModal);
