import React, { useState, useEffect } from 'react';
import { Row, Col, Accordion, AccordionBody, AccordionHeader, AccordionItem } from 'reactstrap';
import { fetchFaqs, updateLoading } from 'store/apps/appConfig';
import { useTranslation } from 'react-i18next';
import { translationFlags } from 'config/translationFlags';
import { ReactComponent as ChevronDown } from 'assets/images/icons/chevronDown.svg';
import { useDispatch, connect } from 'react-redux';
import CustomCard from '../cards/customCard';
import NavigationTitle from '../navigationTitle';
import { ReactComponent as Power } from 'assets/images/logos/PoweredByLokk.svg';

const FaqsModal = ({ theme }) => {

  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [open, setOpen] = useState('');
  const [faqs, setFaqs] = useState(false);
  const [hasScroll, setHasScroll] = useState(false);

  const toggle = (id) => {
    if (open === id) {
      setOpen('');
    } else {
      setOpen(id);
    }
  };

  const changeCountry = (country) => {
    i18n.changeLanguage(country?.code);
  }

  const hasVerticalScrollbar = (element) => {
    if (element) {
      return element.scrollHeight > element.clientHeight;
    }
  }

  useEffect(() => {
    dispatch(updateLoading(true));
    dispatch(fetchFaqs())
      .then((res) => {
        if (res.payload.ResultCode === 1) {
          dispatch(updateLoading(false));
          setFaqs(res.payload?.Data);
        }
        else {
          dispatch(updateLoading(false));
        }
      })
      .catch(() => {
        dispatch(updateLoading(false));
      })
  }, [dispatch])

  useEffect(() => {
    const handleScroll = () => {
      const scrollDiv = document.getElementById('scroll');
      if (scrollDiv && hasVerticalScrollbar(scrollDiv)) {
        setHasScroll(true);
      } else {
        setHasScroll(false);
      }
    };

    const resizeObserver = new ResizeObserver(handleScroll);
    const scrollDiv = document.getElementById('scroll');

    if (scrollDiv) {
      scrollDiv.addEventListener('scroll', handleScroll);
      resizeObserver.observe(scrollDiv);

      return () => {
        scrollDiv.removeEventListener('scroll', handleScroll);
        resizeObserver.unobserve(scrollDiv);
      };
    }
  }, [faqs]);


  return (
    <Row className='m-0 h-100 w-100'>
      <Power style={{ width: "90px", height: "20px", bottom: 10, left: "calc(50% - 45px)", fill: `var(${theme === "lightTheme" ? "--font" : "--white"})` }} className="m-0 m p-0 position-absolute" />
      <Col xs="12" style={{ height: 98 }}>
        <NavigationTitle title={t('app.helpAndFaqs')} modal />
      </Col>
      <Col xs="12" style={{ height: "calc(100% - 148px)" }}>
        <Row style={{ height: "22.5%" }} className="pt-3 m-0">
          {translationFlags.map((item, index) =>
            <Col key={index} xs="3" onClick={() => changeCountry(item)} className="d-flex justify-content-center">
              <div className='m-0 p-0' style={{ width: "30px", height: "45px" }}>
                <CustomCard color={i18n.language === item?.code ? "lightRose" : "secondary"}>
                  <div className='m-0 p-0' style={{ width: "32.5px" }}>{item?.flag}</div>
                </CustomCard>
              </div>
            </Col>
          )}
        </Row>
        <Row className={`overflow-auto customScroll ${hasScroll ? "pe-2" : ""}`} id="scroll" style={{ height: "77.5%" }}>
          <Col xs="12">
            <Row className='h-100'>
              <Accordion flush open={open} toggle={toggle}>
                {
                  faqs && faqs.map((item, index) => (
                    <AccordionItem className={`${index === 0 ? "mb-3" : index === faqs?.length - 1 ? "mt-3" : "my-3"}`} key={index}>
                      <AccordionHeader targetId={`${item?.id}`} style={{ background: "red" }}>
                        <Col xs="11">
                          <Row>
                            <span className='p-0 ps-1 text-5 weight-300' style={{ overflowWrap: "break-word" }}>
                              {item?.title[i18n.language]}
                            </span>
                          </Row>
                        </Col>
                        <Col xs="1">
                          <Row className='d-flex justify-content-end align-items-center pe-1'>
                            <ChevronDown style={{ width: "10px", height: "10px", transform: `rotate(${open === `${item?.id}` ? "180deg" : "0"})`, transition: "0.3s all" }} className="m-0 p-0" />
                          </Row>
                        </Col>
                      </AccordionHeader>
                      <AccordionBody accordionId={`${item?.id}`}>
                        <Row className='px-2'>
                          <span className='m-0 p-0 text-5 weight-300' style={{ textAlign: "justify" }}>
                            {item?.text[i18n.language]}
                          </span>
                        </Row>
                      </AccordionBody>
                    </AccordionItem>
                  ))
                }
              </Accordion>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

const mapStateToProps = (state) => {
  return {
    theme: state?.appConfig?.theme
  };
};

export default connect(mapStateToProps)(FaqsModal);
