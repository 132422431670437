import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from 'helpers/api'

// ** Payment Credit Card
export const checkPaymentCreditCard = createAsyncThunk('user/checkPaymentCreditCard', async (params) => {
  const config = { headers: { "Content-Type": "multipart/form-data" } };
  const response = await api.post(`api/alegro/user/locker/authorize`, params, config)
  return response?.data
})

// ** Payment MB Way
export const checkPaymentMBWay = createAsyncThunk('user/checkPaymentMBWay', async (params) => {
  const config = { headers: { "Content-Type": "multipart/form-data" } };
  const response = await api.post(`api/alegro/user/locker/MBWay`, params, config)
  return response?.data
})

// ** Consult MB Way
export const consultPaymentMBWay = createAsyncThunk('user/consultPaymentMBWay', async (params) => {
  const response = await api.get(`payment/verify?identifier=${params}`)
  return response?.data
})

// * Consult Payment Methods

export const consultPaymentMethods = createAsyncThunk('user/consultPaymentMethods', async (params) => {
  const response = await api.get(`api/marketplace/settings/paymentMethods/consult`)
  return response?.data
})

export const paymentSlice = createSlice({
  name: 'payment',
  initialState: {},
  reducers: {},
})

export default paymentSlice.reducer
