import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { validToken } from 'store/apps/appConfig';

const axiosConfig = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
    'Authorization': `Bearer `,
    'token': ''
  }
});

const AxiosInterceptor = ({ children }) => {
  const dispatch = useDispatch()
  axiosConfig.interceptors.request.use(
    function (config) {
      config.headers['Authorization'] = `Bearer ${process.env.REACT_APP_API_TOKEN}`;
      config.headers['token'] = window.localStorage.getItem('token');
      return config;
    },
    function (error) {
      return Promise.reject(error);
    }
  );

  axiosConfig.interceptors.response.use(
    function (response) {
      dispatch(validToken(true));
      return response
    },
    function (error) {
      console.log(error)
      if (error.response.status === 401) {
        // Handle 401 error
      } else if (error.response.status === 403) {
        if (error?.response?.data?.error === 'qr_token_invalid' || error?.response?.data?.error === 'qr_token_expired') {
          dispatch(validToken(false));
        }
      } else {
        return Promise.reject(error);
      }
    }
  );

  return children;
}

export default axiosConfig;
export { AxiosInterceptor };
