import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux'
import { store, persistor } from './store/config/store';
import { PersistGate } from 'redux-persist/integration/react';
import './i18n';
import 'bootstrap/dist/css/bootstrap.min.css';
import "assets/scss/main.scss"
import { AxiosInterceptor } from 'helpers/api';

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(

  <Provider store={store}>
    <AxiosInterceptor>
      <PersistGate loading={null} persistor={persistor}>
        <Suspense fallback={null}>
          <App />
        </Suspense>
      </PersistGate>
    </AxiosInterceptor>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();