import React from 'react';
import ReactDom from 'react-dom';

const Portal = ({ children, isOpen}) => {

  if (isOpen === false) return null;

  const styles = {
    container: {
      position: 'absolute',
      bottom: '0',
      left: '0',
      zIndex: '50',
      height: 'calc(100% - 71px)',
      width: '100%',
      backgroundColor: "var(--background)",
    }
  }

  return ReactDom.createPortal(
    <div style={styles.container}>
      <div className="m-0 w-100 h-100 px-4 p-0 pb-4">
        {children}
      </div>
    </div>,
    document.getElementById('content-container') ? document.getElementById('content-container') : document.body
  )
}

export default Portal